import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/pc/HomeView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/h5',
      name: 'h5Home',
      component: () => import('@/views/h5/HomeView.vue')
    },
    {
      path: '/h5/detail',
      name: 'h5Detail',
      component: () => import('@/views/h5/DetailView.vue')
    },
    {
      path: '/h5/download',
      name: 'h5Download',
      component: () => import('@/views/h5/DownloadView.vue')
    }
  ]
})

export default router
