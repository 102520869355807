<script setup>
// import { ref } from 'vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
// import required modules
import { Pagination, Autoplay } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

const modules = [Pagination, Autoplay]

// const showAppleQR = ref(false)
// const showAndroidOR = ref(false)
</script>

<template>
  <div id="bg-blue" class="fullw flexY ai-ct bg-highlightaccent">
    <nav id="nav" class="flex ai-ct jc-sb nav fullw">
      <div id="icon-zqdd-wrap">
        <img id="icon-zqdd" src="@/assets/images/web/logo.png" alt="智趣多多">
        <span id="title-zqdd">智趣多多App</span>
      </div>
    </nav>
    <div id="first-page" class="fullw flex jc-sa mt64 x2y fw-wr">
      <div class="rela" id="anim">
        <img src="@/assets/images/web/知识.jpg" id="screenshot-left" class="_show">
        <img src="@/assets/images/web/pic_iphone.png" id="screenshot-iphone" class="rela _show" alt="屏幕截图">
        <!-- <img src="@/assets/images/web/百科.jpg" id="screenshot-right" class="_show"> -->
      </div>
      <!-- <div class="flexY rela">
        <div id="zqdd-slogen">
          <p>探索未知的兴趣</p>
        </div>
      </div> -->
    </div>
  </div>

  <section class="section flexY ai-ct c234">
    <h2 class="fs40 fw5 lh56 ta-ct">发现你的兴趣，就用智趣多多App！</h2>
    <div class="flex jc-sb x2y">
      <div class="flexY ai-ct mt104">
        <img id="illu-1" src="@/assets/images/web/benefits1.png">
        <div class="mt12 fs24 fw5 lh32">海量内容</div>
        <div class="mt12 fs18 fw4 lh24 w-250">无边的知识海洋，让你在探索中不断发现新知，满足好奇心。</div>
      </div>
      <div class="flexY ai-ct mt104">
        <img id="illu-2" src="@/assets/images/web/benefits2.png">
        <div class="mt12 fs24 fw5 lh32">智能助手</div>
        <div class="mt12 fs18 fw4 lh24 w-250">随时随地帮你找信息、解答问题，让生活更轻松。</div>
      </div>
      <div class="flexY ai-ct mt104">
        <img id="illu-3" src="@/assets/images/web/benefits3.png">
        <div class="mt12 fs24 fw5 lh32">新品体验</div>
        <div class="mt12 fs18 fw4 lh24 w-250">抢先感受新鲜事物的魅力，享受科技的惊喜与乐趣。</div>
      </div>
    </div>
  </section>

  <section class="section flexY ai-ct c234" id="content-presentation">
    <!-- <h2 class="fs40 fw5 lh56 ta-ct">致力于为你解决背单词难题</h2>
    <h3 class="fs24 fw4 lh32 mt12 ta-ct">为英语考试扫清障碍</h3> -->
    <div class="mt104 flex ai-ct jc-sb x2y fw-wr width-10">
      <div class="group">
        <div class="red-dot rela fs32 fw5 lh44">历史和世界</div>
        <div class="fs18 fw4 lh32 mt12">坐上时光机旅行，见识各种古老文明，参与发现新大陆，了解不同国家的故事，让你变成小小历史探险家，发现世界多彩的过去和现在</div>
      </div>
      <div id="ss-1" class="ml56 video-ctnr rela flex ai-ct jc-ct _show">
        <img class="abs video-figure"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUwIiBoZWlnaHQ9IjQ1MSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PGNpcmNsZSBpZD0iYSIgY3g9IjIyNSIgY3k9IjIyNSIgcj0iMjI1Ii8+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLjI2NykiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPjx1c2UgeGxpbms6aHJlZj0iI2EiLz48L21hc2s+PHVzZSBmaWxsPSIjMDEzQkQ0IiB4bGluazpocmVmPSIjYSIvPjxjaXJjbGUgZmlsbC1vcGFjaXR5PSIuMSIgZmlsbD0iI0ZGRiIgbWFzaz0idXJsKCNiKSIgY3g9Ii41IiBjeT0iMzE4LjUiIHI9IjE2NC41Ii8+PC9nPjwvc3ZnPg==">
        <swiper class="mySwiper" :loop="true" :pagination="true" :modules="modules"
          :autoplay="{ delay: 2000, disableOnInteraction: false }">
          <swiper-slide><img class="w-320" src="@/assets/images/web/历史.jpg" /></swiper-slide>
          <swiper-slide><img class="w-320" src="@/assets/images/web/世界.jpg" /></swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="mt120 flex ai-ct jc-sb x2y width-10">
      <div id="ss-2" class="video-ctnr rela flex ai-ct jc-ct _show">
        <img class="abs video-figure"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUwIiBoZWlnaHQ9IjQ1MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PGNpcmNsZSBpZD0iYSIgY3g9IjIyNSIgY3k9IjIyNSIgcj0iMjI1Ii8+PC9kZWZzPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPjx1c2UgeGxpbms6aHJlZj0iI2EiLz48L21hc2s+PHVzZSBmaWxsPSIjMDEzQkQ0IiB4bGluazpocmVmPSIjYSIvPjxjaXJjbGUgZmlsbC1vcGFjaXR5PSIuMSIgZmlsbD0iI0ZGRiIgbWFzaz0idXJsKCNiKSIgY3g9IjIyNSIgY3k9IjQ4NCIgcj0iMzMyIi8+PC9nPjwvc3ZnPg==">
        <swiper class="mySwiper" :loop="true" :pagination="true" :modules="modules"
          :autoplay="{ delay: 2000, disableOnInteraction: false }">
          <swiper-slide><img class="w-320" src="@/assets/images/web/科技.jpg" /></swiper-slide>
          <swiper-slide><img class="w-320" src="@/assets/images/web/百科.jpg" /></swiper-slide>
        </swiper>
      </div>
      <div class="group ml56">
        <div class="red-dot rela fs32 fw5 lh44">科技和百科</div>
        <div class="fs18 fw4 lh32 mt12">让你能够跟上时代的步伐，懂得各种新奇玩意儿，帮你变成小小科学家，解答你心中的十万个为什么，还能让你在聊天时有更多有趣的话题</div>
      </div>
    </div>
    <div class="mt120 flex ai-ct jc-sb x2y fw-wr width-10">
      <div class="group">
        <div class="red-dot rela fs32 fw5 lh44">知识和助手</div>
        <div class="fs18 fw4 lh32 mt12">了解知识的“来龙去脉”， 既要知其然，又要知其所以然。智能助手，随时随地帮你找信息、解答问题，让生活更轻松</div>
      </div>
      <div id="ss-3" class="ml56 video-ctnr rela flex ai-ct jc-ct _show">
        <img class="abs video-figure"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUwIiBoZWlnaHQ9IjQ1MSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PGNpcmNsZSBpZD0iYSIgY3g9IjIyNSIgY3k9IjIyNSIgcj0iMjI1Ii8+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLjI2NykiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPjx1c2UgeGxpbms6aHJlZj0iI2EiLz48L21hc2s+PHVzZSBmaWxsPSIjMDEzQkQ0IiB4bGluazpocmVmPSIjYSIvPjxjaXJjbGUgZmlsbC1vcGFjaXR5PSIuMSIgZmlsbD0iI0ZGRiIgbWFzaz0idXJsKCNiKSIgY3g9Ii41IiBjeT0iMzE4LjUiIHI9IjE2NC41Ii8+PC9nPjwvc3ZnPg==">
        <swiper class="mySwiper" :loop="true" :pagination="true" :modules="modules"
          :autoplay="{ delay: 2000, disableOnInteraction: false }">
          <swiper-slide><img class="w-320" src="@/assets/images/web/知识.jpg" /></swiper-slide>
          <swiper-slide><img class="w-320" src="@/assets/images/web/助手.jpg" /></swiper-slide>
        </swiper>
      </div>
    </div>
  </section>

  <footer class="fullw fs14 fw4 cfff bg-black">
    <div class="footer-content fullw flex jc-sb x2y">
      <div class="info">
        <!-- <div class="lh18 mt46 op65">地址: 天津市河西区围堤道146号华盛广场B-16c-A5</div> -->
        <div class="lh18 op65">地址: 天津市河西区围堤道146号华盛广场B座18层f单元</div>
        <div class="mt12"><span class="op65 lh18">天津智趣之美科技有限公司 版权所有 © 2024 - 2025 zhiqufun.com.All Rights Reserved.</span></div>
        <div class="mt12"><span class="op65 lh18">经营许可证编号:津B2-20240440，备案号：津ICP备2024014686号-1</span></div>
        <div class="mt12"><span class="op65 lh18">电话:18518364938 | 邮箱：zhiqu20240308@163.com</span>
        </div>
      </div>
      <!-- <div class="ml32">
        <div class="fs14 op65 lh20">下载百词斩App</div>
        <div class="flex mt16">
          <div class="qr"><img id="qr-apple" src="@/assets/images/web/openinstallqrcode.png" alt="下载百词斩-苹果">
            <div class="fs12 mt12 lh18 op65">App Store</div>
          </div>
          <div class="qr ml56"><img id="qr-android" src="@/assets/images/web/openinstallqrcode.png" alt="下载百词斩-安卓">
            <div class="fs12 mt12 lh18 op65">Android</div>
          </div>
        </div>
      </div> -->
    </div>
  </footer>
</template>

<style scoped>
@import '@/assets/main.css';

.swiper {
  width: 320px;
  height: 568px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>